<template>
    <div class="w-full px-5 py-10 lg:py-20 bg-[url('@/assets/lpProgram/Program-highlights-bg.png')] bg-cover">
      <section class="w-full max-w-7xl mx-auto">
        
        <div class="flex flex-col lg:flex-row gap-16 pt-2 items-center lg:items-start">
          <div>
              <img src="@/assets/lpProgram/program-highlights.webp" alt="" srcset="" class="h-[280px] lg:h-[450px]">
          </div>
          <div>
            <h1 class="text-[30px] lg:text-[60px] font-medium mb-3"><span class="text-white">Program </span><span class="text-[#0AB1EF]">Highights </span></h1>
            <ul class="flex flex-col gap-3 md:gap-6 py-2 px-4 md:px-0">
              <li v-for="higlight in highlights" :key="higlight" class="md:list-disc flex gap-2 text-[16px] md:text-[20px] text-white md:text-left">
                <img src="@/assets/lpProgram/program-highlights-pointer-icon.webp" alt="element icon"
                                    class="w-[20px] h-[20px]" /> <span class="mt-[-5px]">{{ higlight.content }}</span>
              </li>
            </ul>
          </div>
          
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "HighightsView",
    data() {
      return {
        highlights: [
          {
            id: 1,
  
            content:
              "Updated industry-relevant syllabus",
          },
          {
            id: 2,
  
            content:
              "Experienced & professional faculty",
          },
          {
            id: 3,
  
            content:
              "Flexible learning environment",
          },
          {
            id: 4,
  
            content:
              "Multiple electives to choose from",
          },
          {
            id: 5,
  
            content:
              "Live online sessions",
          },
           {
            id: 6,
  
            content:
              "Practical exposure",
          },
        ],
      };
    },
  };
  </script>
  
  <style></style>
  