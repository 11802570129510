<template>
    <div>

        <div class="Lp z-50" v-if="isModalOpen">
            <div class="Lp-content rounded-md relative sm:w-[450px] md:w-[500px]">
                <span class="close" @click="closeModal">&times;</span>
                <PopupForm />
            </div>
        </div>
        <!-- header start-->
        <section class="w-full h-full">
            <div class="bg-[#ffff] py-2 lg:py-2 px-5 border-b-2 border-gray-400">
                <div class="w-full 2xl:w-[55%] mx-auto">

                    <div class="flex justify-between items-center">
                        <a :href="`/`"><img src="@/assets/lpProgramm/sharda-Online-Logo.png" alt="Sharda Online" srcset=""
                                class="" /></a>
                        <a :href="`tel:+91${programData.phone_number}`">
                            <div class="flex justify-between items-center gap-2">
                                <img src="@/assets/lpProgram/phonecall.webp" alt="call-icon" class="w-[30px] h-[30px]" /> 
                                <p class="text-[#123177] py-3 text-[20px] rounded-3xl items-center hidden md:block">
                                    +91 - 8899775544
                                </p>
                            </div>
                        </a>
                    </div>

                    <div class="flex justify-center items-center hidden">
                        <img src="@/assets/lpProgramm/sharda-Online-Logo.png" alt="" srcset="" class="" />
                    </div>
                </div>
            </div>
        </section>
        <!-- header end-->

        <BannerView />
        <AffiliatedView />
        <OnlineProgram v-if="this.programData" :program="this.programData" />
        <HighightsView />
        <WhoapplyView />
        <AboutView />
        <aluminaiWorkView />
        <CareerView />
        <PlacementSupport />
        <enquireView />
        <div class="md:pb-0">
            <FooterView v-if="this.programData" :program="this.programData" />
        </div>

        <!-- <LpStickyView v-if="this.programData" :program="this.programData" />    -->
    </div>
</template>
  
<script>
import { useHead } from "@unhead/vue";
import BannerView from "@/components/lpProgram/BannerView.vue";
import AffiliatedView from "@/components/lpProgram/AffiliatedView.vue";
import OnlineProgram from "@/components/lpProgram/OnlineProgram.vue";
import HighightsView from "@/components/lpProgram/HighlightView.vue";
import WhoapplyView from "@/components/lpProgram/WhoapplyView.vue";
import AboutView from "@/components/lpProgram/AboutView.vue";
import aluminaiWorkView from "@/components/lpProgram/aluminaiwork.vue";
import CareerView from "@/components/home/CareerView.vue";
import PlacementSupport from "@/components/home/PlacementSupport.vue";
import enquireView from "@/components/home/enquireView.vue";
import FooterView from "@/components/lpProgram/FooterView.vue";

//   import LpStickyView from "@/components/lpProgramm/LpStickyView.vue";
//   import PopupForm from "@/components/lpProgramm/PopupForm.vue";

import axios from "axios";

export default {
    name: "lpProgram",
    components: {
        BannerView,
        AffiliatedView,
        OnlineProgram,
        HighightsView,
        WhoapplyView,
        AboutView,
        aluminaiWorkView,
        CareerView,
        PlacementSupport,
        enquireView,
        FooterView
    },
    data() {
        return {
            programData: [],
            lpProgramfaq: [],
            status: false,
            isModalOpen: false,
            fullBanner: false,
        };
    },
    props: ["slug"],
    created() {
        if (this.slug == "online-degree-programs") {
            this.fullBanner = true;
        }
        if (this.slug == "online-degree-program-for-ug-and-pg") {
            useHead({
                script: [
                    {
                        src: "https://www.googletagmanager.com/gtag/js?id=AW-802761477",
                        async: true,
                    },
                    {
                        innerHTML: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-802761477');
              `,
                        type: "text/javascript",
                    },
                ],
                __dangerouslyDisableSanitizers: {
                    script: ["innerHTML"],
                },
            });
        }
        if (this.slug == "online-degrees-india") {
            useHead({
                script: [
                    {
                        src: "https://www.googletagmanager.com/gtag/js?id=AW-16639104395",
                        async: true,
                    },
                    {
                        innerHTML: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-16639104395');
              `,
                        type: "text/javascript",
                    },
                ],
                __dangerouslyDisableSanitizers: {
                    script: ["innerHTML"],
                },
            });
        }
        // this.changeValue(this.slug);
        this.programInfo();
        this.programFaq();
    },
    methods: {
        async programInfo() {
            await axios
                .get(`${process.env.VUE_APP_API}/lp/electives/?slug=${this.slug}`)
                .then((resp) => {
                    if (resp.data.status == 200) {
                        this.programData = resp.data.data[0];
                        console.log(this.programData, "here there is");
                        this.status = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async programFaq() {
            await axios
                .get(`${process.env.VUE_APP_API}/lp/electives/faq`)
                .then((resp) => {
                    if (resp.data.status == 200) {
                        this.lpProgramfaq = resp.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

    },
};
</script>
  
<style>
.Lp {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.Lp-content {
    position: relative;
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
}
</style>
  