<template>
  <div class="max-w-7xl m-auto my-[2rem]">
    <div
      class="flex items-center justify-center lg:justify-between my-5 lg:px-6 xl:px-8"
    >
      <div
        class="text-3xl lg:text-6xl w-[15rem] lg:w-max text-center text-[#18479E] leading-10"
      >
        What does <span class="text-[#EC027C]">SHORE entail?</span>
      </div>
      <div class="hidden lg:block">
        <img src="@/assets/shore/element.png" alt="petals" />
      </div>
    </div>
    <!--desktop desgin-->
    <div class="hidden lg:block">
  <div class="content--sticky">
    <div class="px-8 xl:w-max h-max m-auto relative">
      <div class="bg-[#18479E] rounded-lg">
        <img
          src="@/assets/shore/studentEngagement.png"
          alt="studentEngagement"
        />
      </div>
      <div class="absolute top-[20%] left-[7%] text-4xl text-white">
        <span class="text-6xl font-bold text-[#0CB1EF]">S</span>tudent
        Engagement
      </div>
    </div>
  </div>

  <div class="content--sticky mt-4">
    <div class="px-8 xl:w-max h-max m-auto relative">
      <div class="rounded-lg">
        <img
          src="@/assets/shore/handsOnActivities.png"
          alt="handsOnActivities"
        />
      </div>
      <div class="absolute top-[35%] left-[10%] text-4xl text-[#18479E]">
        <span class="text-6xl font-bold text-[#0CB1EF]">H</span>ands-on
        Activities
      </div>
    </div>
  </div>

  <div class="content--sticky mt-4">
    <div class="px-8 xl:w-max h-max m-auto relative">
      <div class="bg-[#18479E] rounded-lg">
        <img
          src="@/assets/shore/onCampusExperience.png"
          alt="onCampusExperience"
        />
      </div>
      <div class="absolute top-[20%] left-[7%] text-4xl text-white">
        <span class="text-6xl font-bold text-[#0CB1EF]">O</span>n-campus-like
        Experience
      </div>
    </div>
  </div>

  <div class="content--sticky mt-4">
    <div class="px-8 xl:w-max h-max m-auto relative">
      <div class="rounded-lg">
        <img
          src="@/assets/shore/recreationProgram.png"
          alt="recreationProgram"
        />
      </div>
      <div class="absolute top-[35%] left-[10%] text-4xl text-[#18479E]">
        <span class="text-6xl font-bold text-[#0CB1EF]">R</span>ecreational
        Programs
      </div>
    </div>
  </div>

  <div class="content--sticky mt-4">
    <div class="px-8 xl:w-max h-max m-auto relative">
      <div class="bg-[#18479E] rounded-lg">
        <img src="@/assets/shore/exposure.png" alt="exposure" />
      </div>
      <div class="absolute top-[30%] left-[7%] text-4xl text-white">
        <span class="text-6xl font-bold text-[#0CB1EF]">E</span>xposure
      </div>
    </div>
  </div>
</div>
    <!--desktop desgin-->
    <!--mobile desgin-->
    <div class="lg:hidden">
      <div>
        <div v-for="(el, index) in mobDesginData" :key="index">
          <div class="w-max m-auto mb-8">
            <div class="ssm:w-[18rem] w-max">
              <img :src="el.img" alt="el.name" />
            </div>
            <div
              class="bg-[#18479E] text-start ssm:pl-2 pl-4 text-[26px] py-6 text-white rounded-b-xl"
              v-html="el.name"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--mobile desgin-->
  </div>
</template>

<script>
export default {
  name: "shoreEnatail",
  data() {
    return {
      mobDesginData: [
        {
          img: require("@/assets/shore/student_engagement_mob.png"),
          name: "<span class='text-[#0CB1EF] text-[45px] font-bold'>S</span>tudent engagement",
        },
        {
          img: require("@/assets/shore/hands_on_activities_mob.png"),
          name: "<span class='text-[#0CB1EF] text-[45px] font-bold'>H</span>ands-on-activities",
        },
        {
          img: require("@/assets/shore/on_campus_experience_mob.png"),
          name: "<span class='text-[#0CB1EF] text-[45px] font-bold'>O</span>n campus like <br> experience",
        },
        {
          img: require("@/assets/shore/recrational_programm_mob.png"),
          name: "<span class='text-[#0CB1EF] text-[45px] font-bold'>R</span>ecreational programs",
        },
        {
          img: require("@/assets/shore/exposure_mob.png"),
          name: "<span class='text-[#0CB1EF] text-[45px] font-bold'>E</span>xposure",
        },
      ],
    };
  },
};
</script>

<style scoped>
.content--sticky {
  position: sticky;
  top: 20%;
  padding-top: 3rem;
  transition: 0.3s ease;
}
</style>
