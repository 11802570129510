<template>
    <div>
        <headerView />
        <div class="bg-gradient-to-b from-white to-gray-200 px-5 lg:px-8">
            <div class="max-w-7xl mx-auto">
                <div class="flex flex-col justify-center h-[250px] lg:h-[300px] pt-[8rem]  lg:pt-[15rem] lg:pb-20">
                    <h1 class="text-2xl lg:text-5xl text-[#18479E] font-medium lg:font-bold text-left">
                        Centre for Internal Quality Assurance (CIQA)
                    </h1>
                    <div class="w-full my-4 text-start text-blue-700 md:text-black md:text-2xl md:leading-relaxed">
                        Feedback Tools for Stakeholders
                    </div>
                </div>
            </div>
        </div>
        <div class="px-5 lg:px-8">
            <div class="max-w-7xl mx-auto py-10">
                <div class="flex flex-col gap-4">

                    <div class="tabs  w-full lg:w-[300px]">
                        <div class="border-2 border-[#EC027C] rounded-full p-3 cursor-pointer text-center text-[#EC027C] font-bold"
                            v-for="(tab, index) in tabs" :key="index" @click="selectedTab = index"
                            :class="{ active: selectedTab === index }">
                            {{ tab.name }}
                        </div>
                    </div>
                    <div class="tabs-container">
                        <div class="tab-content mt-6 w-full">
                            <div v-if="selectedTab === 0">
                                <h2 class="text-lg lg:text-xl mb-4">
                                    Help us enhance the teaching-learning experience at Sharda
                                    Online by sharing your feedback. Please fill out the form below—your input will be
                                    treated with the utmost confidentiality.
                                </h2>
                                <form @submit.prevent="submitForm1" class="space-y-8">
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <input type="text" id="name" v-model="form1.name" placeholder="Your Name*"
                                            class="border placeholder-blue-600 rounded w-full text-blue-700 lg:w-1/2 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                            autocomplete="off" maxlength="255" required
                                            @input="form1.name = form1.name.replace(/[^a-zA-Z\s]/g, '')" />
                                        <input type="text" id="registration_number" v-model="form1.registration_number"
                                            placeholder="Registration Number*"
                                            class="border rounded placeholder-blue-600 w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required />
                                    </div>
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <select id="program" v-model="form1.program"
                                            class="border rounded w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required>
                                            <option disabled value="">Select Program</option>
                                            <option v-for="program in programs" :key="program" :value="program">{{ program
                                            }}</option>
                                        </select>
                                        <select id="age_group" v-model="form1.age_group"
                                            class="border rounded w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required>
                                            <option disabled value="">Age Group</option>
                                            <option v-for="age_group in ages" :key="age_group" :value="age_group">{{
                                                age_group
                                            }}</option>
                                        </select>
                                        <select id="working_professional" v-model="form1.working_professional"
                                            class="border rounded w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required>
                                            <option disabled value="">Are You a Working Professional*</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    </div>
                                    <hr class="my-8">
                                    <h2 class="mb-2">Kindly rate us on a scale of 1 to 5, with 5 indicating the highest
                                        rating.</h2>
                                    <!-- Questions with Ratings -->
                                    <div v-for="(question, index) in form1.questions_rating" :key="index" class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2">{{ question.question
                                        }}</label>
                                        <div class="flex items-center space-x-4">
                                            <label v-for="n in 5" :key="n" class="">
                                                <input type="radio" :value="n" v-model="question.rating" required /> {{ n }}
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Questions Ratings -->
                                    <div class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2" for="feedback">
                                            9. Do you have any additional suggestions or feedback that could help us enhance
                                            our services?
                                        </label>
                                        <textarea id="feedback" v-model="form1.feedback"
                                            class="border rounded w-full py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            rows="4" required></textarea>
                                    </div>
                                    <p>We appreciate your valuable feedback. Your responses will be kept confidential.</p>
                                    <div class="flex items-center justify-center">
                                        <button :disabled="!isForm1Valid" type="submit"
                                            class="bg-[#EC027C] border-2 rounded-full p-3 hover:bg-pink-700 text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline"
                                            :class="{ 'opacity-50 cursor-not-allowed': !isForm1Valid }">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div v-if="selectedTab === 1">
                                <h2 class="text-lg lg:text-xl mb-4">
                                    Sharda Online is committed to delivering exceptional education to its learners. To help
                                    us continually improve our teaching and learning processes, we value your input. Please
                                    take a few moments to complete the feedback form below.
                                </h2>
                                <form @submit.prevent="submitForm3" class="space-y-8">
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <input type="text" id="name" v-model="form3.name" placeholder="Your Name*"
                                            class="border placeholder-blue-600 rounded w-full text-blue-700 lg:w-1/2 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                            autocomplete="off" maxlength="255" required
                                            @input="form3.name = form3.name.replace(/[^a-zA-Z\s]/g, '')" />
                                        <input type="text" id="employee_id" v-model="form3.employee_id"
                                            placeholder="Employee ID*"
                                            class="border rounded placeholder-blue-600 w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required />
                                    </div>
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <select id="age_group" v-model="form3.age_group"
                                            class="border rounded w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required>
                                            <option disabled value="">Age Group*</option>
                                            <option value="Less than 20">Less than 20</option>
                                            <option value="20-30">20-30</option>
                                            <option value="31-40">31-40</option>
                                            <option value="41-50">41-50</option>
                                            <option value="Above">Above 50</option>

                                        </select>
                                        <select id="gender" v-model="form3.gender"
                                            class="border rounded w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required>
                                            <option disabled value="">Gender*</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>

                                        </select>
                                    </div>
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <select id="academic_discipline" v-model="form3.academic_discipline"
                                            class="border rounded w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required>
                                            <option disabled value="">Academic Discipline*</option>
                                            <option value="Management">Management</option>
                                            <option value="Commerce">Commerce</option>
                                            <option value="Arts">Arts</option>
                                            <option value="Computer Science and IT">Computer Science and IT</option>
                                            <option value="Others">Others</option>

                                        </select>
                                        <input type="text" id="experience_in_sharda_online"
                                            v-model="form3.experience_in_sharda_online"
                                            placeholder="Total Experience with Sharda Online (In years)*"
                                            class="border rounded placeholder-blue-600 w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required />
                                    </div>
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <select id="name_of_the_program_handling"
                                            v-model="form3.name_of_the_program_handling"
                                            class="border rounded w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required>
                                            <option disabled value="">Name of the Program You Handle*</option>
                                            <option value="MBA">MBA</option>
                                            <option value="MCA">MCA</option>
                                            <option value="M.Com">M.Com</option>
                                            <option value="BBA">BBA</option>
                                            <option value="BCA">BCA</option>
                                            <option value="BA">BA</option>
                                        </select>
                                        <input type="text" id="number_of_courses_handling"
                                            v-model="form3.number_of_courses_handling"
                                            placeholder="Number of Courses You Handle*"
                                            @input="form3.number_of_courses_handling = form3.number_of_courses_handling.replace(/[^0-9]/g, '')"
                                            maxlength="10"
                                            class="border rounded placeholder-blue-600 w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required />

                                    </div>
                                    <hr class="my-8">
                                    <h2 class="mb-2">Kindly rate us on a scale of 1 to 5, with 5 indicating the highest
                                        rating.</h2>
                                    <!-- Questions with Ratings -->
                                    <div v-for="(question, index) in form3.question_ratings" :key="index" class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2">{{ question.question
                                        }}</label>
                                        <div class="flex items-center space-x-4">
                                            <label v-for="n in 5" :key="n">
                                                <input type="radio" :value="n" v-model="question.rating" /> {{ n }}
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Questions Ratings -->

                                    <div class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2" for="feedback">
                                            9. Do you have any additional suggestions or feedback that could help us enhance
                                            our services?
                                        </label>
                                        <textarea id="feedback" v-model="form3.feedback"
                                            class="border rounded w-full py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            rows="4" required></textarea>
                                    </div>
                                    <p>We appreciate your valuable feedback. Your responses will be kept confidential.</p>
                                    <div class="flex items-center justify-center">
                                        <button :disabled="!isForm2Valid" type="submit"
                                            class="bg-[#EC027C] border-2 rounded-full p-3 hover:bg-pink-700 text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline"
                                            :class="{ 'opacity-50 cursor-not-allowed': !isForm2Valid }">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div v-if="selectedTab === 2">
                                <h2 class="text-lg lg:text-xl mb-4">
                                    Thank you for placing your trust in Sharda Online for your ward's education. We value
                                    your feedback as it is essential for us to improve our services and better serve our
                                    learners.
                                </h2>
                                <form @submit.prevent="submitForm2" class="space-y-8">
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <input type="text" id="name" v-model="form2.name" placeholder="Your Name*"
                                            class="border placeholder-blue-600 rounded w-full text-blue-700 lg:w-1/2 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                            autocomplete="off" maxlength="255" required
                                            @input="form2.name = form2.name.replace(/[^a-zA-Z\s]/g, '')" />
                                        <input type="text" id="student_name" v-model="form2.student_name"
                                            placeholder="Student Name*" autocomplete="off" maxlength="255"
                                            class="border rounded placeholder-blue-600 w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            @input="form2.student_name = form2.student_name.replace(/[^a-zA-Z\s]/g, '')"
                                            required />
                                    </div>
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <input type="text" id="registration_number" v-model="form2.registration_number"
                                            placeholder="Registration/Enrollment Number*"
                                            class="border rounded placeholder-blue-600 w-full lg:w-full py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required />
                                    </div>
                                    <hr class="my-8">
                                    <h2 class="mb-2">Kindly rate us on a scale of 1 to 5, with 5 indicating the highest
                                        rating.</h2>
                                    <!-- Questions with Ratings -->
                                    <div v-for="(question, index) in form2.questions_rating" :key="index" class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2">{{ question.question
                                        }}</label>
                                        <div class="flex items-center space-x-4">
                                            <label v-for="n in 5" :key="n" class="">
                                                <input type="radio" :value="n" v-model="question.rating" required /> {{ n }}
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Questions Ratings -->
                                    <div class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2" for="feedback">
                                            8. Do you have any additional suggestions or feedback that could help us enhance
                                            our services?
                                        </label>
                                        <textarea id="feedback" v-model="form2.feedback"
                                            class="border rounded w-full py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            rows="4" required></textarea>
                                    </div>
                                    <p>We appreciate your valuable feedback. Your responses will be kept confidential.</p>
                                    <div class="flex items-center justify-center">
                                        <button :disabled="!isForm3Valid" type="submit"
                                            class="bg-[#EC027C] border-2 rounded-full p-3 hover:bg-pink-700 text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline"
                                            :class="{ 'opacity-50 cursor-not-allowed': !isForm3Valid }">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div v-if="selectedTab === 3">
                                <h2 class="text-lg lg:text-xl mb-4">
                                    We appreciate your organization's commitment to providing career opportunities to our
                                    alumni. To ensure we continue delivering exceptional graduates to the workforce, your
                                    feedback is important. Please spare a few minutes to complete the feedback form below
                                    and share your thoughts on the contributions of our alumni to your organization.
                                </h2>
                                <form @submit.prevent="submitForm4" class="space-y-8">
                                    <!-- Name and Registration Number -->
                                    <div class="flex flex-col lg:flex-row lg:space-x-4">
                                        <input type="text" id="name" v-model="form4.name" placeholder="Your Name*"
                                            class="border placeholder-blue-600 rounded w-full text-blue-700 lg:w-1/2 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                            autocomplete="off" maxlength="255" required
                                            @input="form4.name = form4.name.replace(/[^a-zA-Z\s]/g, '')" />
                                        <input type="text" id="register_number" v-model="form4.register_number"
                                            placeholder="Registration Number*"
                                            class="border rounded placeholder-blue-600 w-full lg:w-1/2 py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            required />
                                    </div>

                                    <!-- Ratings Section -->
                                    <hr class="my-8">
                                    <h2 class="mb-2">Kindly rate us on a scale of 1 to 5, with 5 indicating the highest
                                        rating.</h2>
                                    <div v-for="(question, index) in form4.question_ratings" :key="index" class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2">{{ question.question
                                        }}</label>
                                        <div class="flex items-center space-x-4">
                                            <label v-for="n in 5" :key="n" class="">
                                                <input type="radio" :value="n" v-model="question.rating" required /> {{ n }}
                                            </label>
                                        </div>
                                    </div>

                                    <!-- Feedback Section -->
                                    <div class="mb-4">
                                        <label class="block text-blue-700 text-sm font-bold mb-2" for="feedback">
                                            9. Do you have any additional suggestions or feedback that could help us enhance
                                            our services?
                                        </label>
                                        <textarea id="feedback" v-model="form4.feedback"
                                            class="border rounded w-full py-2 px-3 text-blue-700 leading-tight focus:outline-none focus:shadow-outline"
                                            rows="4" required></textarea>
                                    </div>

                                    <p>We appreciate your valuable feedback. Your responses will be kept confidential.</p>

                                    <!-- Submit Button -->
                                    <div class="flex items-center justify-center">
                                        <button :disabled="!isForm4Valid" type="submit"
                                            class="bg-[#EC027C] border-2 rounded-full p-3 hover:bg-pink-700 text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline"
                                            :class="{ 'opacity-50 cursor-not-allowed': !isForm4Valid }">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footerView />
        <stickyView />
    </div>
</template>
resetForm
<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import stickyView from "@/components/common/sticky.vue";
import axios from 'axios';  // Assuming axios is used for API requests

export default {
    name: "FeedbackView",
    components: {
        headerView,
        footerView,
        stickyView,
    },
    data() {
        return {
            selectedTab: 0, // Default to the first tab
            tabs: [
                { name: "Learners" },
                { name: "Teacher" },
                { name: "Parents" },
                { name: "Employer" },
            ],
            form1: {
                name: "",
                registration_number: "",
                program: "",
                working_professional: "",
                age_group: "",
                feedback: "",
                questions_rating: [
                    {
                        question: "1. The program guide/handbook clearly defines the program outcomes.",
                        rating: ''
                    },
                    {
                        question: "2. The structure, scheme, and syllabi are well-defined in the program guide/handbook.",
                        rating: ''
                    },
                    {
                        question: "3. The SLM promotes independent study, featuring characteristics such as being self-explanatory, self-contained, self-directed, self-motivating, and self-evaluating.",
                        rating: ''
                    },
                    {
                        question: "4. The SLM provides comprehensive knowledge of the course, covering each aspect of the syllabus.",
                        rating: ''
                    },
                    {
                        question: "5. The learning outcomes are clearly defined in every unit.",
                        rating: ''
                    },
                    {
                        question: "6. The assignments effectively help in evaluating content knowledge.",
                        rating: ''
                    },
                    {
                        question: "7. Project work, fieldwork, internships, and practicals (where applicable) have provided the necessary skills and competencies.",
                        rating: ''
                    },
                    {
                        question: "8. The pattern of questions in the End Term Examinations is well-structured to assess subject knowledge.",
                        rating: ''
                    }
                ]
            },
            programs: [
                'MBA',
                'MCA',
                'M.Com',
                'BBA',
                'B.Com',
                'BA(Hons.)'
            ],
            ages: [
                'Less than 20',
                '20-30',
                '31-40',
                '41-50',
                'Above 50'
            ],

            // Data for Form 2
            form2: {
                name: '',
                student_name: '',
                registration_number: '',
                feedback: '',
                questions_rating: [
                    {
                        question: "1. How satisfied are you with the academic content provided by Sharda Online?",
                        rating: ''
                    },
                    {
                        question: "2. How user-friendly is the Sharda Online learning platform?",
                        rating: ''
                    },
                    {
                        question: "3. How satisfied are you with the quality of the Self-Learning Materials provided to your ward?",
                        rating: ''
                    },
                    {
                        question: "4. How satisfied are you with the support provided for your ward's academic progress?",
                        rating: ''
                    },
                    {
                        question: "5. How satisfied are you with the examination process and evaluation mechanism?",
                        rating: ''
                    },
                    {
                        question: "6. How likely are you to recommend Sharda Online to others?",
                        rating: ''
                    },
                    {
                        question: "7. Would you like us to offer additional programs/electives beyond the current ones? If so, please specify the names of the programs you have in mind.",
                        rating: ''
                    }
                ]
            },

            // Data for Form 3
            form3: {
                name: '',
                employee_id: '',
                age_group: '',
                gender: '',
                academic_discipline: '',
                experience_in_sharda_online: '',
                name_of_the_program_handling: '',
                number_of_courses_handling: '',
                feedback: '',
                question_ratings: [
                    {
                        question: "1. The program's development is aligned with its specific learning outcomes.",
                        rating: ''
                    },
                    {
                        question: "2. Each unit clearly defines the learning outcomes.",
                        rating: ''
                    },
                    {
                        question: "3. The curriculum includes recent and emerging topics in the subject area.",
                        rating: ''
                    },
                    {
                        question: "4. The curriculum includes elements that help learners acquire the necessary skills and competencies.",
                        rating: ''
                    },
                    {
                        question: "5. Assignments are designed in alignment with the program's syllabus.",
                        rating: ''
                    },
                    {
                        question: "6. Project work, field work, internships, or practicals (where applicable) assist learners in gaining the desired skills and competencies.",
                        rating: ''
                    },
                    {
                        question: "7. End-of-term examination questions are aligned with the expected program outcomes.",
                        rating: ''
                    },
                    {
                        question: "8. The integration of digital technologies effectively enhances student engagement in the learning process.",
                        rating: ''
                    }
                ]
            },
            // Data for Form 4
            form4: {
                name: '',
                register_number: '',
                feedback: '',
                question_ratings: [
                    {
                        question: "1. Effectiveness in verbal and written communication",
                        rating: ''
                    },
                    {
                        question: "2. Proficiency in technical skills",
                        rating: ''
                    },
                    {
                        question: "3. Demonstration of commitment and efficiency",
                        rating: ''
                    },
                    {
                        question: "4. Teamwork and collaboration abilities",
                        rating: ''
                    },
                    {
                        question: "5. Ability to handle challenges and problem-solving skills",
                        rating: ''
                    },
                    {
                        question: "6. Ability to adapt to new situations and learn new skills quickly",
                        rating: ''
                    },
                    {
                        question: "7. Overall job performance in your organization",
                        rating: ''
                    },
                    {
                        question: "8. Overall quality of education and training.",
                        rating: ''
                    }
                ]
            },
        };
    },
    computed: {
        isForm1Valid() {
            // Check if all required fields are filled
            const nameFilled = this.form1.name.trim() !== '';
            const regNumberFilled = this.form1.registration_number.trim() !== '';
            const programFilled = this.form1.program.trim() !== '';
            const working_professionalFilled = this.form1.working_professional.trim() !== '';
            const age_groupFilled = this.form1.age_group.trim() !== '';
            const feedbackFilled = this.form1.feedback.trim() !== '';

            // Ensure all ratings are filled
            const allRatingsFilled = this.form1.questions_rating.every(question => question.rating !== '');

            // Return true if all required fields are filled
            return nameFilled && regNumberFilled && programFilled && working_professionalFilled && age_groupFilled && feedbackFilled && allRatingsFilled;
        },
        isForm3Valid() {
            // Check if all required fields are filled
            const nameFilled = this.form2.name.trim() !== '';
            const regNumberFilled = this.form2.registration_number.trim() !== '';
            const student_nameFilled = this.form2.student_name.trim() !== '';
            const feedbackFilled = this.form2.feedback.trim() !== '';

            // Ensure all ratings are filled
            const allRatingsFilled = this.form2.questions_rating.every(question => question.rating !== '');

            // Return true if all required fields are filled
            return nameFilled && regNumberFilled && student_nameFilled && feedbackFilled && allRatingsFilled;
        },
        isForm2Valid() {
            // Check if all required fields are filled
            const nameFilled = this.form3.name.trim() !== '';
            const employee_idFilled = this.form3.employee_id.trim() !== '';
            const genderFilled = this.form3.gender.trim() !== '';
            const age_groupFilled = this.form3.age_group.trim() !== '';
            const academic_disciplineFilled = this.form3.academic_discipline.trim() !== '';
            const experience_in_sharda_onlineFilled = this.form3.experience_in_sharda_online.trim() !== '';
            const name_of_the_program_handlingFilled = this.form3.name_of_the_program_handling.trim() !== '';
            const number_of_courses_handlingFilled = this.form3.number_of_courses_handling.trim() !== '';
            const feedbackFilled = this.form3.feedback.trim() !== '';

            // Ensure all ratings are filled
            const allRatingsFilled = this.form3.question_ratings.every(question => question.rating !== '');

            // Return true if all required fields are filled
            return nameFilled && employee_idFilled && genderFilled && age_groupFilled && academic_disciplineFilled && experience_in_sharda_onlineFilled  && name_of_the_program_handlingFilled  && number_of_courses_handlingFilled  && feedbackFilled && allRatingsFilled;
        },
        isForm4Valid() {
            // Check if all required fields are filled
            const nameFilled = this.form4.name.trim() !== '';
            const regNumberFilled = this.form4.register_number.trim() !== '';
            const feedbackFilled = this.form4.feedback.trim() !== '';

            // Ensure all ratings are filled
            const allRatingsFilled = this.form4.question_ratings.every(question => question.rating !== '');

            // Return true if all required fields are filled
            return nameFilled && regNumberFilled && feedbackFilled && allRatingsFilled;
        }
    },
    methods: {
        async submitForm1() {
            try {
                // Check if all questions have a selected rating
                const allRated = this.form1.questions_rating.every(question => question.rating !== '');

                if (!allRated) {
                    alert('Please rate all the questions before submitting.');
                    return; // Stop form submission if not all questions are rated
                }

                console.log('Data being sent:', JSON.stringify(this.form1, null, 2)); // Log the form data
                const response = await axios.post('https://apistage.shardaonline.online/api/v1/grievance/learner-create/', this.form1);
                console.log('Response:', response.data);
                alert('Thank you! Form has been submitted successfully. Our team will get in touch with you soon.');
                this.resetForm();
            } catch (error) {
                console.error('There was an error submitting the form:', error.response ? error.response.data : error.message);
                alert('An error occurred. Please fill all fields and try again.');
            }
        },
        resetForm() {
            this.form1 = {
                name: "",
                registration_number: "",
                program: "",
                age_group: "",
                working_professional: "",
                feedback: "",
                questions_rating: this.form1.questions_rating.map(question => ({ ...question, rating: '' }))
            };
        },

        async submitForm2() {
            try {
                // Check if all questions have a selected rating
                const allRated = this.form2.questions_rating.every(question => question.rating !== '');

                if (!allRated) {
                    alert('Please rate all the questions before submitting.');
                    return; // Stop form submission if not all questions are rated
                }

                console.log('Data being sent:', JSON.stringify(this.form2, null, 2)); // Log the form data
                const response = await axios.post('https://apistage.shardaonline.online/api/v1/grievance/parent-create/', this.form2);
                console.log('Response:', response.data);
                alert('Thank you! Form has been submitted successfully. Our team will get in touch with you soon.');
                this.resetForm1();
            } catch (error) {
                console.error('There was an error submitting the form:', error.response ? error.response.data : error.message);
                alert('An error occurred. Please fill all fields and try again.');
            }
        },
        resetForm1() {
            this.form2 = {
                name: '',
                student_name: '',
                registration_number: '',
                feedback: '',
                questions_rating: this.form2.questions_rating.map(question => ({ ...question, rating: '' }))
            };
        },

        async submitForm3() {
            try {
                // Check if all questions have a selected rating
                const allRated = this.form3.question_ratings.every(question => question.rating !== '');

                if (!allRated) {
                    alert('Please rate all the questions before submitting.');
                    return; // Stop form submission if not all questions are rated
                }

                console.log('Data being sent:', JSON.stringify(this.form3, null, 2)); // Log the form data
                const response = await axios.post('https://apistage.shardaonline.online/api/v1/grievance/employee-create/', this.form3);
                console.log('Response:', response.data);
                alert('Thank you! Form has been submitted successfully. Our team will get in touch with you soon.');
                this.resetForm2(); // Reset the form after submission
            } catch (error) {
                console.error('Error details:', error.response ? error.response.data : error.message);
                alert('An error occurred. Please fill all fields and try again.');
            }
        },
        resetForm2() {
            this.form3 = {
                name: '',
                employee_id: '',
                age_group: '',
                gender: '',
                academic_discipline: '',
                experience_in_sharda_online: '',
                name_of_the_program_handling: '',
                number_of_courses_handling: '',
                feedback: '',
                question_ratings: this.form3.question_ratings.map(question => ({ ...question, rating: '' }))
            };
        },
        async submitForm4() {
            try {
                // Check if all questions have a selected rating
                const allRated = this.form4.question_ratings.every(question => question.rating !== '');

                if (!allRated) {
                    alert('Please rate all the questions before submitting.');
                    return; // Stop form submission if not all questions are rated
                }

                console.log('Data being sent:', JSON.stringify(this.form4, null, 2)); // Log the form data
                const response = await axios.post('https://apistage.shardaonline.online/api/v1/grievance/employer-create/', this.form4);
                console.log('Response:', response.data);
                alert('Thank you! Form has been submitted successfully. Our team will get in touch with you soon.');
                this.resetForm3(); // Reset the form after submission
            } catch (error) {
                console.error('Error details:', error.response ? error.response.data : error.message);
                alert('An error occurred. Please fill all fields and try again.');
            }
        },
        resetForm3() {
            this.form4 = {
                name: '',
                register_number: '',
                feedback: '',
                question_ratings: this.form4.question_ratings.map(question => ({ ...question, rating: '' }))
            };
        }

    },
};
</script>

<style scoped>
.tabs-container {
    display: flex;
    flex-direction: column;
}

.tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 30px;
    width: 60%;
}

.tabs div {
    cursor: pointer;
    border: 1px solid #EC027C;
    flex: 1;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.tabs div:hover {
    background-color: #FF90CA;
    color: #fff;
}

.tabs div.active {
    background-color: #EC027C;
    color: white;
}

.tab-content {
    padding: 15px;
    background-color: #fff;
}

form {
    margin-top: 20px;
}

button {
    transition: background-color 0.3s;
}

select,
input,
textarea {
    text-transform: none;
    background: #fff;
    margin-top: 10px;
}</style>