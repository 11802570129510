<template>
  <div class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF] lg:py-10">
    <div class="max-w-7xl m-auto">
      <!--mobile desgin-->
      <div class="md:hidden">


        <div class="flex flex-col p-[1.5rem]" v-for="(data, index) in bannerData" :key="index">
          <div class="">
            <img :src="data.bannerImg" alt="bannerImage" />
          </div>
          <div>
            <div class="pt-4 text-4xl font-bold text-[#18479E]" v-html="data.heading"></div>
            <div class="text-[#0CB1EF] text-[55px] w-max mt-[-4%]" style="
                  font-family: northCost;
                  font-style: italic;
                  line-height: 1;
                  letter-spacing: 3.5px;
                ">
              {{ data.subHeading }}
            </div>
            <div class="pl-1 text-[#18479E] font-bold text-xl">
              {{ data.tags }}
            </div>

            <div class="pl-1 font-extrabold pt-3 text-[14px]">
              {{ data.degreename }}
            </div>
            <div class="text-white font-extrabold text-[16px] bg-[#ec027c] w-[11rem] pl-2">
              {{ data.date }}
            </div>
            <div class="pl-1 font-extralight pt-3 text-[14px]">
              {{ data.degrees }}
            </div>
            <div class="text-[#18479E] pl-1 font-bold">
              {{ data.degreeOffered }}
            </div>
            <a href="https://apply.shardaonline.ac.in/" target="_blank">
              <div class="rounded-full border border-[#EC027C] w-max px-8 py-2 text-[#EC027C] mt-4 ml-1">
                Apply Now
              </div>
            </a>
          </div>
          <div class="lg:hidden pt-5 lg:flex justify-center items-center xl:w-[500px]">
            <div class="border py-4 bg-white shadow-lg">
              <lpenquire v-if="this.programData" :program="this.programData" />
            </div>
          </div>
        </div>



      </div>

      <div class="relative mt-2 hidden md:block">

        <div class="!flex justify-around items-center" v-for="(el, index) in bannerDataDesk" :key="index">
          <div class="animate-text">
            <div class="text-4xl lg:text-5xl xl:text-6xl text-[#18479E] font-bold" v-html="el.heading"></div>
            <div class="text-[50px] lg:text-[80px] xl:text-[100px] text-[#0CB1EF] mt-[-5%]" style="
                  font-family: northCost;
                  font-style: italic;
                  letter-spacing: 7px;
                  line-height: 1;
                ">
              {{ el.subHeading }}
            </div>
            <div class="text-2xl lg:text-3xl font-bold text-[#18479E] pb-4">
              {{ el.tags }}
            </div>

            <div class="text-[#18479E] font-bold text-[18px]">
              {{ el.degreename }}
            </div>
            <div class="text-white font-extrabold text-[20px] bg-[#ec027c] w-[13rem] pl-1 whitespace-nowrap">
              {{ el.date }}
            </div>
            <div class="mt-6 text-[26px] font-medium">{{ el.degrees }}</div>
            <div v-html="el.degreeOffered" class="text-[#18479E] font-bold text-[25px]"></div>
            <a href="https://apply.shardaonline.ac.in/" target="_blank">
              <div
                class="rounded-full border border-[#EC027C] w-max px-8 py-2 text-[#EC027C] mt-4 ml-1 bg-white cursor-pointer">
                Apply Now
              </div>
            </a>
          </div>
          <div class="flex items-end">

            <div class="w-[23rem] lg:w-[26rem] xl:w-[32rem]">
              <div class="border py-4 bg-white shadow-lg">
                <lpenquire v-if="this.programData" :program="this.programData" />
              </div>
            </div>
          </div>
        </div>


      </div>
      <!--desktop desging-->
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
import "slick-carousel";
import { useHead } from "@unhead/vue";
import lpenquire from "@/components/lpProgram/lpenquire.vue";

import axios from "axios";

export default {
  name: "bannerView",
  components: {
    lpenquire,

  },

  data() {
    return {
      programData: [],
      bannerData: [
        {
          bannerImg: require("@/assets/home/header/bannermobile-1.webp"),
          heading: "YOUR ROAD TO <br/> ",
          subHeading: "Success begins here!",
          tags: "#RahoHarKadamAage",
          degreename: "Batch starts on:",
          date: "28th September 2024",
          degrees: "Online Degrees Programs:",
          degreeOffered: "MBA | M.Com | MCA | BBA | BCA | BA(Hons.) ",
        },
      ],
      bannerDataDesk: [

        {
          bannerImg: require("@/assets/home/header/bannerdesktop-1.webp"),
          heading: "YOUR ROAD TO <br/> ",
          subHeading: "Success begins here!",
          tags: "#RahoHarKadamAage",
          degreename: "Batch starts on:",
          date: "28th September 2024",
          degrees: "Online Degrees Programs:",
          degreeOffered: "MBA | M.Com | MCA | BBA | BCA | BA(Hons.) ",
        },
      ],

    };
  },

  props: ["slug"],
  created() {
    if (this.slug == "online-course-programs") {
      this.fullBanner = true;
    }
    if (this.slug == "online-course-program") {
      useHead({
        script: [
          {
            src: "https://www.googletagmanager.com/gtag/js?id=AW-802761477",
            async: true,
          },
          {
            innerHTML: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-802761477');
            `,
            type: "text/javascript",
          },
        ],
        __dangerouslyDisableSanitizers: {
          script: ["innerHTML"],
        },
      });
    }
    if (this.slug == "online-degrees-india") {
      useHead({
        script: [
          {
            src: "https://www.googletagmanager.com/gtag/js?id=AW-16639104395",
            async: true,
          },
          {
            innerHTML: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16639104395');
            `,
            type: "text/javascript",
          },
        ],
        __dangerouslyDisableSanitizers: {
          script: ["innerHTML"],
        },
      });
    }
    // this.changeValue(this.slug);
    this.programInfo();
    this.programFaq();
  },
  methods: {
    async programInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/lp/electives/?slug=${this.slug}`)
        .then((resp) => {
          console.log(resp, "here there is");
          if (resp.data.status == 200) {
            this.programData = resp.data.data[0];
            console.log(this.programData, "here there is");
            this.status = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async programFaq() {
      await axios
        .get(`${process.env.VUE_APP_API}/lp/electives/faq`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.lpProgramfaq = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
};
</script>

<style>
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  padding: 0;
  list-style-type: none;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots button {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: #18479e;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #ec027c;
  width: 0.5rem;
  height: 0.5rem;
}

.banner_second_dots_mob {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  padding: 0;
  list-style-type: none;
  position: absolute;
  bottom: -18%;
  left: 30%;
}

.banner_second_dots_mob {
  margin: 0 0.25rem;
}

.banner_second_dots_mob button {
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}

.banner_second_dots_mob li.slick-active button {
  background-color: white;
  width: 0.3rem;
  height: 0.3rem;
}

.banner_second_dots {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  padding: 0;
  list-style-type: none;
  position: absolute;
  bottom: -30%;
  left: 30%;
}

.banner_second_dots li {
  margin: 0 0.25rem;
}

.banner_second_dots button {
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}

.banner_second_dots li.slick-active button {
  background-color: white;
  width: 0.3rem;
  height: 0.3rem;
}

@media (min-width: 1024px) {
  .banner_second_dots {
    bottom: -15px;
    /* Adjust bottom positioning */
    left: 30%;
    /* Adjust horizontal positioning */
  }
}

@media (min-width: 1440px) {
  .banner_second_dots {
    bottom: -20px;
    /* Adjust bottom positioning */
    left: 30%;
    /* Adjust horizontal positioning */
  }
}

.animate-text {
  animation: animateIn 1.5s ease forwards;
  animation-delay: 0.5s;
  /* Adjust delay as needed */
}

@keyframes animateIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
