<template>
  <div
    class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF] h-[525px] md:h-[600px] lg:h-[500px] xl:h-[450px]"
  >
    <div class="max-w-7xl m-auto py-2 lg:py-10">
      <div
        class="text-[#EC027C] text-center ssm:text-2xl text-3xl md:text-4xl lg:text-6xl"
      >
        <span class="text-[#18479E]">Where </span> our Alumni
        <span class="text-[#18479E]">Work</span>
      </div>
      <!--mobdesgin-->
      <div
        class="lg:hidden flex flex-wrap ssm:w-[18rem] w-[20rem] md:w-[36rem] gap-4 items-center m-auto py-8"
      >
        <div class="flex flex-wrap gap-[1rem]">
          <div class="flex gap-[1rem]">
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 0"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Adani-wilmar-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 0 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 1"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Amar-Ujala-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 1 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 2"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Amazon-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 2 }"
              />
            </div>
          </div>
          <div class="flex gap-[1rem]">
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 2"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/CMC-Limited-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 2 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 3"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/DishTV-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 3 }"
              />
            </div>
            <!-- <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 4"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Future-Group-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 4 }"
              />
            </div> -->
          </div>
          <div class="flex gap-[1rem]">
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 5"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Genpact-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 5 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 6"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/HCL-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 6 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 7"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/ICICI-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 7 }"
              />
            </div>
          </div>
          <div class="flex gap-[1rem]">
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 8"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/NIIT-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 8 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 8"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Pepsi-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 8 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 9"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Sleepwell-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 9 }"
              />
            </div>
          </div>
          <div class="flex gap-[1rem]">
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 10"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Syntel-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 10 }"
              />
            </div>
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 11"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Tech-Mahindra-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 11 }"
              />
            </div>
            <!-- <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 12"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Vodafone-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 12 }"
              />
            </div> -->
          </div>
          <div class="m-auto">
            <div
              class="image-container-mobile"
              @mouseover="hoveredImage = 13"
              @mouseleave="hoveredImage = -1"
            >
              <img
                src="@/assets/home/newHiring/Zee-TV-mobile.webp"
                alt=""
                class="grayscale-image"
                :class="{ 'zoom-in-out': hoveredImage === 13 }"
              />
            </div>
          </div>
        </div>
      </div>
      <!--mobdesgin-->
      <!--desdesgin-->
      <div class="hidden lg:flex flex-wrap mx-auto gap-8 py-8">
        <div class="flex mx-auto flex-wrap gap-x-4">
          <div
            class="image-container"
            @mouseover="hoveredImage = 0"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/Adani-wilmar-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 0 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 1"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/Amar-wilmar-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 1 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 2"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/amazon-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 2 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 3"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/cmc-wilmar-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 3 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 4"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/distv-destop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 4 }"
            />
          </div>
          <!-- <div
            class="image-container"
            @mouseover="hoveredImage = 5"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/future-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 5 }"
            />
          </div> -->
        </div>

        <div class="flex mx-auto flex-wrap gap-x-4">
          <div
            class="image-container"
            @mouseover="hoveredImage = 6"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/genpact-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 6 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 7"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/HCL-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 7 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 7"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/ICICI-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 7 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 8"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/NIIT-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 8 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 9"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/pepsi-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 9 }"
            />
          </div>
          <div
            class="image-container"
            @mouseover="hoveredImage = 10"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/sleepwell-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 10 }"
            />
          </div>
        </div>

        <div class="flex mx-auto flex-wrap gap-x-4">
          <div
            class="image-container"
            @mouseover="hoveredImage = 11"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/syntel-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 11 }"
            />
          </div>

          <div
            class="image-container"
            @mouseover="hoveredImage = 11"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/tech-mahindra-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 11 }"
            />
          </div>
          <!-- <div
            class="image-container"
            @mouseover="hoveredImage = 11"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/voda-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 11 }"
            />
          </div> -->
          <div
            class="image-container"
            @mouseover="hoveredImage = 11"
            @mouseleave="hoveredImage = -1"
          >
            <img
              src="@/assets/home/newHiring/zeetv-desktop.webp"
              alt=""
              class="grayscale-image"
              :class="{ 'zoom-in-out': hoveredImage === 11 }"
            />
          </div>
        </div>
      </div>
      <!--desdesgin-->
    </div>
  </div>
</template>

<script>
export default {
  name: "aluminaiWorkView",
  data() {
    return {
      hoveredImage: -1,
    };
  },
};
</script>

<style scoped>
.image-container {
  width: 8rem;
  cursor: pointer;
  position: relative; /* Needed for the absolute positioning of the image */
}
.image-container-mobile {
  cursor: pointer;
  position: relative;
}

.grayscale-image {
  width: 100%;
  height: auto;
  filter: grayscale(1); /* Start with grayscale */
  transition: transform 0.3s ease, filter 0.3s ease; /* Transition for zoom and grayscale effect */
}

.image-container:hover .grayscale-image {
  filter: grayscale(0); /* Remove grayscale on hover */
}

.image-container:hover .grayscale-image {
  transform: scale(1.1); /* Increase size on hover */
}
</style>
