<template>
    <div class="bg-[#f6f6f6] py-10">
        <h1 class="text-[30px] lg:text-[60px] leading-tight text-center hidden md:block">
            <span class="text-[#18479E]">Online</span> <span class="text-[#EC027C]">Degree Programs</span>
        </h1>
        <h1 class="text-[30px] lg:text-[60px] leading-tight text-center  pb-4 md:hidden">
            <span class="text-[#18479E]">Online</span><br> <span class="text-[#EC027C]">Degree Programs</span>
        </h1>
        <section class="max-w-7xl mx-auto px-5 lg:px-5 lg:py-8 2xl:px-0">
            <!-- Tabs for desktop, accordion for mobile -->
            <div v-if="isDesktop">
                <div class="flex justify-center items-center gap-x-4 md:gap-x-[5rem] mt-4 mb-4">
                    <div v-for="el in degreeData" :key="el.id">
                        <span
                            class="font-bold text-sm lg:text-xl cursor-pointer rounded-lg lg:px-4 px-1 pt-2 pb-2 whitespace-nowrap w-[5rem]"
                            @click="matchingId(el.id)"
                            :class="el.id == contentId ? 'border-b-4 bg-[#ec027c] text-white rounded-lg lg:px-4 pt-2 pb-2' : 'bg-[#A3B5D8] text-white'">
                            {{ el.title }}
                        </span>
                    </div>
                </div>

                <div v-for="el in degreeData" :key="el.id" class="text-center">
                    <div v-if="el.id == contentId || (isMobile && el.id == activeAccordionId)">
                        <p class="text-[18px] lg:text-[20px]" :class="[
                            isExpandedDescription
                                ? 'text-center flex flex-col gap-2 font-normal'
                                : 'text-center line-clamp-3 md:line-clamp-2',
                        ]">
                            {{ el.description }}
                        </p>
                        <button @click="toggleReadMore" class="text-[#ec027c] text-[14px] lg:text-[18px]">
                            {{ isExpandedDescription ? "Read less..." : "Read more..." }}
                        </button>
                        <div class="w-full max-w-7xl mx-auto">
                            <h1 class="text-center mb-4 mt-4 text-[20px] lg:text-[30px]">
                                <span class="text-[#18479E]">Electives</span> <span class="text-[#EC027C]">Offered:</span>
                            </h1>
                            <ul class="flex flex-wrap justify-between py-2 px-4 md:px-0">
                                <li v-for="list in el.lists" :key="list"
                                    class="w-[400px] text-start text-[16px] lg:text-[18px] flex gap-2 py-2">
                                    <img src="@/assets/lpProgram/Electives-offered-listing-icon.webp" alt="element icon"
                                        class="w-[18px] h-[18px]" /> <span class="mt-[-5px]">{{ list.content }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Accordion for mobile -->
            <div v-else>
                <div v-for="el in degreeData" :key="el.id">
                    <button @click="toggleAccordion(el.id)"
                        class="w-full py-2 text-[18px] text-center font-semibold text-white mb-2 flex rounded-3xl justify-between items-center px-4"
                        :class="{
                            'bg-[#EC027C]': isAccordionOpen(el.id),
                            'bg-[#A3B5D8]': !isAccordionOpen(el.id)
                        }">
                        {{ el.title }}
                        <!-- Dropdown icon -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            :class="{ 'text-white': isAccordionOpen(el.id), 'text-white': !isAccordionOpen(el.id) }"
                            class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>

                    <div v-if="isAccordionOpen(el.id)" class="p-4">
                        <p class="text-[18px] lg:text-[20px]" :class="[
                            isExpandedDescription
                                ? 'text-center flex flex-col gap-2 font-normal'
                                : 'text-center line-clamp-3 md:line-clamp-2',
                        ]">
                            {{ el.description }}
                        </p>
                        <button @click="toggleReadMore" class="text-[#ec027c] text-center text-[18px] block mx-auto">
                            {{ isExpandedDescription ? "Read less..." : "Read more..." }}
                        </button>
                        <div class="w-full max-w-7xl mx-auto border m-8 shadow rounded bg-[#efefef]">
                            <h1 class="text-center mb-4 mt-4 text-[20px] lg:text-[30px]">
                                <span class="text-[#18479E]">Electives</span> <span class="text-[#EC027C]">Offered:</span>
                            </h1>
                            <ul class="flex flex-wrap justify-between py-2 px-4 md:px-0">
                                <li v-for="list in el.lists" :key="list.id"
                                    class="w-[400px] text-start text-[16px] lg:text-[18px] flex gap-2 py-2">
                                    <img src="@/assets/lpProgram/Electives-offered-listing-icon.webp" alt="element icon"
                                        class="w-[18px] h-[18px]" />
                                    <span class="mt-[-5px]">{{ list.content }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
export default {
    name: "OnlineProgram",
    data() {
        return {
            contentId: 1,
            isExpandedDescription: false,
            accordionOpen: 1,
            isDesktop: window.innerWidth >= 1024,
            degreeData: [
                {
                    id: 1,
                    title: "MBA",
                    description:
                        "The Sharda Online MBA degree program is a dynamic two-year journey tailored for individuals seeking to enhance their careers in business management and leadership. Our Online MBA courses encompass vital subjects like financial analysis, market research, strategic planning, project management, negotiation, leadership, and communication. Through a diverse array of electives, Sharda University Online MBA learners acquire practical skills and deep knowledge essential for success in various industries. Our goal is to empower learners with advanced expertise, guiding them toward shaping successful professional paths. ",
                    lists: [
                        {
                            id: 1,
                            content: "Finance",
                        },
                        {
                            id: 2,
                            content: "Marketing",
                        },
                        {
                            id: 3,
                            content: "Human Resource Management",
                        },

                        {
                            id: 4,
                            content: "Data Science and Analytics",
                        },
                        {
                            id: 5,
                            content: "Digital Marketing and E-commerce",
                        },
                        {
                            id: 6,
                            content: "Healthcare and Hospital Administration",
                        },
                        {
                            id: 7,
                            content: "Operations Management",
                        },
                        {
                            id: 8,
                            content: "International Business",
                        },
                        {
                            id: 9,
                            content: "Logistics and Supply Chain Management",
                        },
                        {
                            id: 10,
                            content: "Project Management",
                        },
                        {
                            id: 11,
                            content: "International Finance (Integrated with ACCA, UK)",
                        },

                    ],
                },
                {
                    id: 2,
                    title: "MCA",
                    description:
                        "The Sharda University Online MCA Degree is a 2-year program focused on teaching the latest skills in computer application and software development. Covering various technology-based domains, our curriculum explores a wide range of essential topics for today's tech professionals. Throughout the program, learners learn programming languages like Java, Python, C++, etc., and topics like database management systems, web development frameworks, cloud computing platforms, and more. With flexible learning options, learners can learn at their own pace and gain a solid grasp of IT essentials. Our MCA program provides students with valuable expertise tailored to the ever-changing demands of the digital world, empowering them to succeed in today's tech-focused environment. ",
                    lists: [

                        {
                            id: 1,
                            content: "Data Science",
                        },
                        {
                            id: 2,
                            content: "Artificial Intelligence",
                        },
                        {
                            id: 3,
                            content: "Cyber security",
                        },
                        {
                            id: 4,
                            content: "Cloud Computing",
                        },
                        {
                            id: 5,
                            content: "Computer Science and Information Technology",
                        },
                    ],
                },
                {
                    id: 3,
                    title: "M.Com",
                    description:
                        "The Sharda University Online M.Com (Master of Commerce) program is a two-year degree tailored to propel learners in areas like commerce, business management, leadership, accounting, finance, taxation, banking, and insurance. In an Online M.Com program, learners gain an in-depth understanding of crucial areas such as financial analysis, market research, strategic planning, project management, negotiation, leadership, and communication. At the same time, the online master's focus is on providing practical skills like analytical thinking, decision-making, and managerial expertise. The comprehensive education from Sharda University Online equips learners to excel in dynamic business environments, empowering them to forge successful paths in their professional journeys.",
                    lists: [
                        {
                            id: 1,
                            content: "Accounting and Finance",
                        },
                        {
                            id: 2,
                            content: "International Finance (Integrated with ACCA, UK)",
                        },
                    ],
                },
                {
                    id: 4,
                    title: "BBA",
                    description:
                        "The Sharda University Online BBA program, which spans three years, is ideal for individuals aspiring to excel in business administration. Covering vital subjects like marketing, finance, and human resource management, our program is tailored to meet current job market demands. We ensure students acquire in-depth knowledge and skills essential for success in business and management roles. Graduates of our program enjoy diverse career opportunities, including positions such as marketing manager, financial analyst, human resource specialist, business consultant, and entrepreneur. With Sharda Online BBA, embark on a transformative educational journey that enriches your learning and unlocks numerous exciting career pathways in business administration. ",
                    lists: [
                        {
                            id: 1,
                            content: "BBA Program",
                        },
                        {
                            id: 2,
                            content: "International Finance (Integrated with ACCA, UK)",
                        },
                    ],
                },
                {
                    id: 5,
                    title: "BCA",
                    description:
                        "The Sharda University Online BCA degree program is a three-year journey dedicated to equipping learners with essential knowledge in computer applications such as web development, database management, and software engineering, all vital for thriving in modern industry settings. Our bachelor's program covers a diverse range of topics carefully curated to provide in-depth insights into specialized areas like machine learning, artificial intelligence, Java, and SQL, which are highly valued in today's job market. Our Online BCA curriculum prepares learners for successful careers in technical and software domains, focusing on enriching computer knowledge and developing vital competencies and skills. ",
                    lists: [
                        {
                            id: 1,
                            content: "BCA Program",
                        },
                    ],
                },
                {
                    id: 6,
                    title: "BA (Hons.)",
                    description:
                        "The BA (Hons.) Degree is a comprehensive three-year undergraduate program typically focusing on a specific field of study within the humanities, social sciences, or arts. It offers in-depth theoretical knowledge, practical skills, and critical thinking abilities tailored to the chosen discipline. This degree fosters a deeper understanding of societal dynamics, historical contexts, creative expression, and political discourses, empowering graduates to navigate complex challenges with insight and innovation.",
                    lists: [
                        {
                            id: 1,
                            content: "Political Science",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        matchingId(id) {
            this.contentId = id;
        },
        toggleReadMore() {
            this.isExpandedDescription = !this.isExpandedDescription;
        },
        toggleAccordion(id) {
            this.accordionOpen = this.accordionOpen === id ? null : id;
        },
        isAccordionOpen(id) {
            return this.accordionOpen === id;
        },
        handleResize() {
            this.isDesktop = window.innerWidth >= 1024;
        }
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
};
</script>
  
<style scoped>
/* Add any mobile-specific styles if needed */
</style>
  