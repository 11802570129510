<template>
    <div class="px-5 bg-[#F6F6F6]">
        <div class="max-w-7xl mx-auto py-20 lg:py-20">
            <div class="flex flex-wrap-reverse lg:flex-nowrap gap-9 lg:gap-40 justify-center">
                <div class="flex flex-col gap-4 justify-center">
                    <h1 class="text-[30px] lg:text-[40px] xl:text-[60px] leading-tight font-normal">
                        <span class="text-[#18479E]">About </span><span class="text-[#EC027C]"> Sharda Online</span>
                    </h1>
                    
                    <p class="text-[18px] lg:text-[24px] lg:w-[600px]">
                        Sharda Online, the e-learning arm of Sharda University, is a
                        new initiative undertaken to provide learning opportunities to
                        aspirational learners with the flexibility to enhance their
                        knowledge without leaving their homes. We emphasize
                        experiential online learning opportunities throughout the
                        program curriculum, including live online classes, recorded
                        video lectures, self-learning material, course assignments,
                        discussion forums for peer-group learning, and other
                        industry-driven projects. 
                    </p>
                </div>
                <div class="w-full lg:w-[450px] xl:w-[515px]">
                    <img src="@/assets/lpProgram/about-sharda-online.webp" alt="" srcset=""
                        class="w-full h-[297px] lg:w-[450px]  xl:w-[515px] lg:h-[481px]" />
                </div>


            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "AboutView",
};
</script>
  
<style></style>
  