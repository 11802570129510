<template>
  <section
    class="p-block relative m-auto justify-center items-center "
  >
<div class="max-w-7xl m-auto justify-center items-center scroll-smooth">
    <div
      class="flex flex-col items-center sticky -z-10 top-[5rem] mb-[10rem] transition-all duration-700"
    >
      <div class="m-auto text-3xl lg:text-6xl text-[#18479E] mt-[3rem]">
        <h2
          class="scroll-mt-header-geo-bar lg:scroll-mt-header-geo-bar-lg heading-m"
        >
          Expectation <br />
          <span class="text-[#EC027C]">meets reality</span>
        </h2>
      </div>
    </div>
    <div class="flex flex-row mt-26 lg:mt-0 m-auto justify-center items-center">
      <div class="relative md:w-[50%] z-10 bg-background mb-16 lg:mb-0">
        <div class="flex flex-col justify-between flex-1 md:px-8 mt-[-8rem] px-1 py-2">
          <div class="flex">
            <div class="relative">
              <img
                src="@/assets/shore/practicalLearning.png"
                alt="practicalLearning"
                class="w-[18rem] bg-gradient-to-b form-white to-black"
              />
              <div
                class="absolute bottom-[33%] font-semibold right-[30%] text-white"
              >
                Practical learning
              </div>
            </div>
            <div class="relative mt-[6rem]">
              <img
                src="@/assets/shore/immersiongs_programm.png"
                alt="immersingProgram"
                class="w-[18rem]"
              />
              <div
                class="absolute bottom-[14%] font-semibold right-[30%] text-white"
              >
                Immersion programs
              </div>
            </div>
          </div>
          <div class="flex md:mt-[-6rem]">
            <div class="">
              <div class="relative">
                <img
                  src="@/assets/shore/webinar_industry.png"
                  alt="practicalLearning"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[22%] font-semibold left-[16%] text-white"
                >
                  Webinars with <br />
                  industry experts
                </div>
              </div>
            </div>
            <div class="left-[22%]">
              <div class="relative mt-[6rem]">
                <img
                  src="@/assets/shore/passionDriven.png"
                  alt="immersingProgram"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[14%] font-semibold left-[20%] text-white"
                >
                  Passion-driven <br />
                  workshops and contests
                </div>
              </div>
            </div>
          </div>
          <div class="flex md:mt-[-6rem]">
            <div class="">
              <div class="relative">
                <img
                  src="@/assets/shore/global_networking.png"
                  alt="practicalLearning"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[22%] font-semibold left-[16%] text-white"
                >
                  Global networking <br />
                  opportunities
                </div>
              </div>
            </div>
            <div class="left-[22%]">
              <div class="relative mt-[6rem]">
                <img
                  src="@/assets/shore/online_and_offline.png"
                  alt="immersingProgram"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[14%] font-semibold left-[20%] text-white"
                >
                  Online and offline events
                </div>
              </div>
            </div>
          </div>
          <div class="flex md:mt-[-6rem]">
            <div class="">
              <div class="relative">
                <img
                  src="@/assets/shore/wellness_activities.png"
                  alt="practicalLearning"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[22%] font-semibold left-[16%] text-white"
                >
                  Wellness activities
                </div>
              </div>
            </div>
            <div class="left-[22%]">
              <div class="relative mt-[6rem]">
                <img
                  src="@/assets/shore/industryExposure.png"
                  alt="immersingProgram"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[14%] font-semibold left-[20%] text-white"
                >
                  Industry exposure
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative hidden md:block md:w-[50%] mt-[-8rem] z-10 bg-background md:mr-[5%] lg:mb-12">
        <div class="flex flex-col justify-between flex-1 px-8 py-6">
          <div class="flex mt-[6rem]">
            <div class="">
              <div class="relative">
                <img
                  src="@/assets/shore/global_networking.png"
                  alt="practicalLearning"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[22%] font-semibold left-[16%] text-white"
                >
                  Global networking <br />
                  opportunities
                </div>
              </div>
            </div>
            <div class="left-[22%]">
              <div class="relative md:mt-[-6rem]">
                <img
                  src="@/assets/shore/online_and_offline.png"
                  alt="immersingProgram"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[14%] font-semibold left-[20%] text-white"
                >
                  Online and offline events
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="">
              <div class="relative">
                <img
                  src="@/assets/shore/wellness_activities.png"
                  alt="practicalLearning"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[22%] font-semibold left-[16%] text-white"
                >
                  Wellness activities
                </div>
              </div>
            </div>
            <div class="left-[22%]">
              <div class="relative md:mt-[-5rem]">
                <img
                  src="@/assets/shore/industryExposure.png"
                  alt="immersingProgram"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[14%] font-semibold left-[20%] text-white"
                >
                  Industry exposure
                </div>
              </div>
            </div>
          </div>
           <div class="flex">
            <div class="relative">
              <img
                src="@/assets/shore/practicalLearning.png"
                alt="practicalLearning"
                class="w-[18rem] bg-gradient-to-b form-white to-black"
              />
              <div
                class="absolute bottom-[33%] font-semibold right-[30%] text-white"
              >
                Practical learning
              </div>
            </div>
            <div class="relative md:mt-[-6rem]">
              <img
                src="@/assets/shore/immersiongs_programm.png"
                alt="immersingProgram"
                class="w-[18rem]"
              />
              <div
                class="absolute bottom-[14%] font-semibold right-[30%] text-white"
              >
                Immersion programs
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="">
              <div class="relative">
                <img
                  src="@/assets/shore/webinar_industry.png"
                  alt="practicalLearning"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[22%] font-semibold left-[16%] text-white"
                >
                  Webinars with <br />
                  industry experts
                </div>
              </div>
            </div>
            <div class="left-[22%]">
              <div class="relative md:mt-[-6rem]">
                <img
                  src="@/assets/shore/passionDriven.png"
                  alt="immersingProgram"
                  class="w-[18rem]"
                />
                <div
                  class="absolute bottom-[14%] font-semibold left-[20%] text-white"
                >
                  Passion-driven <br />
                  workshops and contests
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "expectionView",
  mounted() {
    this.observeImages();
  },
  methods: {
    observeImages() {
      const images = document.querySelectorAll('img');
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-up');
            observer.unobserve(entry.target);
          }
        });
      }, observerOptions);
      images.forEach(image => {
        observer.observe(image);
      });
    }
  }
};
</script>
<style scoped>
p-block {
  padding: 5rem 4rem;
}
.scroll-smooth {
  scroll-behavior: smooth;
}
/* Animation for images */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Class to add to images when they come into view */
.fade-in-up {
  opacity: 0; /* Start as invisible */
  animation: fadeInUp 1s forwards;
}
/* Add transition effects to enhance appearance */
img {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
img.fade-in-up {
  opacity: 0; /* Start as invisible */
  animation: fadeInUp 1s forwards;
}
html {
  scroll-behavior: smooth;
}
/* Animation for images */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Class to add to images when they come into view */
.fade-in-up {
  opacity: 0; /* Start as invisible */
  animation: fadeInUp 1s forwards;
}
/* Transition effects to enhance appearance */
img {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
img.fade-in-up {
  opacity: 0; /* Start as invisible */
  animation: fadeInUp 1s forwards;
}
</style>