<template>
  <div class="bg-black">
    <div class="w-full max-w-7xl mx-auto">
      <div class="flex flex-col lg:flex-row p-3 gap-5">
        <!-- <a :href="`tel:+91${program.phone_number}`">
            <div class="flex gap-2 items-center">
              <img
                src="@/assets/lpProgramm/Iconmaterial-call.svg"
                alt=""
                srcset=""
                class="w-[20px] h-[20px]"
              />
              <p class="text-white text-sm md:text-lg">Call us :+91 {{this.program.phone_number}}</p>
              <p class="text-white text-sm md:text-lg">Call us :+91 8899775544</p>
            </div>
          </a> -->
        <div class="flex gap-x-10 justify-center items-center max-w-6xl m-auto pt-4">
          <a href="https://x.com/ShardaO58166" target="_blank">
            <div><img src="@/assets/home/header/x.svg" alt="x" /></div>
          </a>
          <a href="https://www.facebook.com/people/Sharda-Online/61558918626847/" target="_blank">
            <div>
              <img src="@/assets/home/header/facebook.svg" alt="facebook" />
            </div>
          </a>
          <a href="https://www.instagram.com/sharda_online/" target="_blank">
            <div>
              <img src="@/assets/home/header/instagram.svg" alt="instgram" />
            </div>
          </a>
          <a href="https://www.linkedin.com/company/sharda-online/" target="_blank">
            <div>
              <img src="@/assets/home/header/linkedin.svg" alt="linkedin" />
            </div>
          </a>
          <a href="https://www.youtube.com/user/shardauniv" target="_blank">
            <div><img src="@/assets/home/header/youtube.svg" alt="youtube" /></div>
          </a>
        </div>

        <!-- <div class="flex gap-2 items-center">
            <img
              src="@/assets/lp-program/icons8-location-50.png"
              alt=""
              srcset=""
              class="w-[20px] h-[20px] text-white"
            />
            <p class="text-white text-sm md:text-lg text-left">
              Sharda University Plot No. 32-34, Knowledge Park III, Greater Noida,
              Uttar Pradesh - 201310
            </p>
          </div> -->
      </div>
      <hr class="text-[#696969]">
      <div class="text-white text-center py-4 text-sm">
        © 2024 Sharda ONLINE
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "FooterView",
  props: ["program"],
};
</script>
  
<style></style>
  