<template>
  <div class="mb-0">
    <!--mobile desgin-->
    <div
      class="bg-gradient-to-b from-[#184498] to-[#000919] lg:hidden pb-[100px]"
    >
      <div class="">
        <div class="text-white w-[17rem] text-3xl py-2 m-auto text-center">
          Ready to make your career bloom?
        </div>
        <a href="https://apply.shardaonline.ac.in/">
          <div
            class="bg-[#EC027C] text-white text-center w-max rounded-full py-2 px-10 font-semibold m-auto cursor-pointer"
          >
            Apply Now
          </div>
        </a>
        <div class="flex gap-x-8 mt-10 pl-4 md:pl-8">
          <div><img src="@/assets/home/header/call.svg" alt="call" /></div>
          <div class="text-white text-[18px]">
            <a href="tel:6361202000">+91 6361202000</a>
          </div>
        </div>
        <div class="flex gap-x-8 mt-6 pl-4 md:pl-8">
          <div><img src="@/assets/home/header/whatsapp.svg" alt="call" /></div>
          <div class="text-white text-[18px]">
            <a
              href="https://api.whatsapp.com/send/?phone=6361202000"
              target="_blank"
              >+91 8877661139</a
            >
          </div>
        </div>
        <div class="flex gap-x-8 mb-10 mt-6 pl-4 md:pl-8">
          <div><img src="@/assets/home/header/email.svg" alt="call" /></div>
          <div class="text-white text-[18px]">
            <a href="mailto:info@sharda.ac.in">info@shardaonline.ac.in</a>
          </div>
        </div>
        <div
          class="h-[.1rem] ssm:w-[17rem] w-[20rem] m-auto bg-[#FFFFFF] opacity-20"
        ></div>
      </div>
      <!--programms-->
      <div class="mt-6">
        <div
          class="flex justify-between items-center px-8 cursor-pointer"
          @click="toggleProgramm = !toggleProgramm"
        >
          <div class="text-white text-lg">Programs</div>
          <div v-if="!toggleProgramm">
            <img
              src="@/assets/home/header/Icon-ionic-ios-arrow-down.svg"
              alt="inconic-down"
            />
          </div>
          <div v-else>
            <img src="@/assets/home/header/arrow-up.svg" alt="inconic-up" />
          </div>
        </div>
        <div v-show="toggleProgramm">
          <div class="flex gap-x-20 ml-10">
            <div class="text-white mt-6">
              <a href="/masters-business-administration-online-degree"
                ><div class="text-[14px]">MBA</div></a
              >
              <a href="/master-of-commerce-online-degree"
                ><div class="pt-4 text-[14px]">M.Com</div></a
              >
              <a href="/masters-computer-applications-online-degree">
                <div class="pt-4 text-[14px]">MCA</div></a
              >
            </div>
            <div class="text-white mt-6">
              <a href="/bachelor-computer-application-online-degree">
                <div class="text-[14px]">BCA</div></a
              >
              <a href="/bachelor-of-arts-online-degree">
                <div class="pt-4 text-[14px]">BA (Hons.)</div></a
              >
              <a href="/bachelors-business-administration-online-degree">
                <div class="pt-4 text-[14px]">BBA</div></a
              >
            </div>
          </div>
        </div>
        <div
          class="h-[.1rem] ssm:w-[17rem] w-[20rem] m-auto bg-[#FFFFFF] opacity-20 mt-8 mb-4"
        ></div>
        <div
          class="flex justify-between items-center px-8 cursor-pointer"
          @click="toggleSharda = !toggleSharda"
        >
          <div class="text-white text-lg">Sharda Online</div>
          <div v-if="!toggleSharda">
            <img
              src="@/assets/home/header/Icon-ionic-ios-arrow-down.svg"
              alt="inconic-down"
            />
          </div>
          <div v-else>
            <img src="@/assets/home/header/arrow-up.svg" alt="inconic-up" />
          </div>
        </div>
        <div v-show="toggleSharda">
          <div class="ml-10 text-white my-2">
            <a href="/about-us"> <div class="pt-4">About Us</div></a>
            <a href="/shore"> <div class="pt-4">SHORE</div></a>
            <a href="https://souls.shardaonline.ac.in/" target="_blank"> <div class="pt-4">Student Support</div></a>

            <a href="/blogs"><div class="pt-4">Blogs</div></a>
            <a href="/contact-us"><div class="pt-4">Contact Us</div></a>
          </div>
        </div>
        <div
          class="h-[.1rem] ssm:w-[17rem] w-[20rem] m-auto bg-[#FFFFFF] opacity-20 mt-8 mb-4"
        ></div>
        <div
          class="flex justify-between items-center px-8 cursor-pointer"
          @click="toggleOtherLink = !toggleOtherLink"
        >
          <div class="text-white text-lg">Other Useful Links</div>
          <div v-if="!toggleOtherLink">
            <img
              src="@/assets/home/header/Icon-ionic-ios-arrow-down.svg"
              alt="inconic-down"
            />
          </div>
          <div v-else>
            <img src="@/assets/home/header/arrow-up.svg" alt="inconic-up" />
          </div>
        </div>
        <div v-show="toggleOtherLink">
          <div class="ml-10 text-white my-2">
            <a href="/academic-resources"
              ><div class="pt-4">Academic Resources</div></a
            >
            <a href="/mandatory-disclosure"
              ><div class="pt-4">Manadatory Disclosure</div></a
            >
            <a href="/Student-Underaking-August-2024.docx"><div class="pt-4">Student Undertaking  Policies</div></a>
            <a href="/refund"><div class="pt-4">Refund Policy</div></a>
            <a href="/privacy-policy"><div class="pt-4">Privacy Policy</div></a>
            <a href="https://nad.digilocker.gov.in/"
              ><div class="pt-4">DigiLocker</div></a
            >
            <a href="/feedback"
              ><div class="pt-4">Grievance</div></a
            >
          </div>
        </div>
        <div class="relative cursor-pointer">
          <div
            class="h-[.1rem] ssm:w-[17rem] w-[20rem] m-auto bg-[#FFFFFF] opacity-20 mt-8"
          ></div>
          <div class="absolute bottom-[-8px] right-[50%]">
            <img
              src="@/assets/home/header//down-arrow.png"
              alt="inconic-down"
              class="w-[1.4rem] h-[1.4rem] rounded-full p-1"
            />
          </div>
        </div>
        <div class="text-white m-auto text-[12px] text-center mt-4 pb-[1rem]">
          © 2024 Sharda ONLINE
        </div>
      </div>
      <!--programms-->
    </div>
    <!--mobile desgin-->
    <!--desktop desgin-->
    <div
      class="bg-gradient-to-b from-[#184498] to-[#000919] hidden lg:block mx-auto"
    >
      <div class="text-center text-white text-[60px] 2xl:text-7xl py-4 lg:py-7">
        Ready to make your career bloom?
      </div>
      <a href="https://apply.shardaonline.ac.in/">
        <div
          class="bg-[#EC027C] text-white text-center w-max rounded-full py-3 px-8 font-semibold m-auto cursor-pointer"
        >
          Apply Now
        </div>
      </a>
      <div class="flex justify-evenly mt-8 2xl:max-w-7xl 2xl:m-auto 2xl:pt-8">
        <div class="flex gap-x-6">
          <div><img src="@/assets/home/header/call.svg" alt="call" /></div>
          <a href="tel:6361202000">
            <div class="text-white text-[18px]">+91 6361202000</div></a
          >
        </div>
        <div class="flex gap-x-6">
          <div><img src="@/assets/home/header/whatsapp.svg" alt="call" /></div>
          <a
            href="https://api.whatsapp.com/send/?phone=6361202000"
            target="_blank"
          >
            <div class="text-white text-[18px]">+91 6361202000</div></a
          >
        </div>
        <div class="flex items-center gap-x-6">
          <div><img src="@/assets/home/header/email.svg" alt="call" /></div>
          <a href="mailto:info@sharda.ac.in">
            <div class="text-white text-[18px]">info@shardaonline.ac.in</div></a
          >
        </div>
      </div>
      <div class="h-[.1rem] w-full m-auto bg-[#FFFFFF] opacity-20 mt-8"></div>
      <div
        class="flex justify-around text-white xl:max-w-6xl xl:m-auto xl:pt-8 mt-8"
      >
        <div>
          <a href="/masters-business-administration-online-degree">
            <div class="mb-4">MBA</div></a
          >
          <a href="/master-of-commerce-online-degree">
            <div class="mb-4">M.Com</div></a
          >
          <a href="/masters-computer-applications-online-degree">
            <div>MCA</div></a
          >
        </div>
        <div>
          <a href="/bachelor-computer-application-online-degree">
            <div class="mb-4">BCA</div></a
          >
          <a href="/bachelor-of-arts-online-degree">
            <div class="mb-4">BA (Hons.)</div></a
          >
          <a href="/bachelors-business-administration-online-degree">
            <div>BBA</div></a
          >
        </div>
        <div>
          <a href="/about-us"><div class="mb-4">About Us</div></a>
          <a href="/shore"><div class="mb-4">SHORE</div></a>
            <a href="https://souls.shardaonline.ac.in/" target="_blank"> <div class="mb-4">Student Support</div></a>

          <a href="/contact-us"><div class="mb-4">Contact Us</div></a>
          <a href="/blogs"><div>Blog</div></a>
        </div>
        <div>
          <a href="/academic-resources"
            ><div class="mb-4">Academic Resources</div></a
          >
          <a href="/mandatory-disclosure"
            ><div class="mb-4">Mandatory Disclosure</div></a
          >
          <a href="https://nad.digilocker.gov.in/"> <div>DigiLocker</div></a>
          <a href="/feedback"
              ><div class="pt-4">Grievance</div></a
            >
        </div>
        <div>
          <a href="/Student-Underaking-August-2024.docx"><div class="mb-4">Student Undertaking  Policies</div></a>
          <a href="/refund"><div class="mb-4">Refund Policy</div></a>
          <a href="/privacy-policy"><div class="mb-4">Privacy Policy</div></a>
        </div>
      </div>
      <div
        class="flex gap-x-10 justify-center items-center max-w-6xl m-auto pt-8"
      >
        <a href="https://x.com/ShardaO58166"
          ><div><img src="@/assets/home/header/x.svg" alt="x" /></div
        ></a>
        <a href="https://www.facebook.com/people/Sharda-Online/61558918626847/">
          <div>
            <img src="@/assets/home/header/facebook.svg" alt="facebook" /></div
        ></a>
        <a href="https://www.instagram.com/sharda_online/">
          <div>
            <img src="@/assets/home/header/instagram.svg" alt="instgram" /></div
        ></a>
        <a href="https://www.linkedin.com/company/sharda-online/">
          <div>
            <img src="@/assets/home/header/linkedin.svg" alt="linkedin" /></div
        ></a>
        <a href="https://www.youtube.com/user/shardauniv">
          <div><img src="@/assets/home/header/youtube.svg" alt="youtube" /></div
        ></a>
      </div>
      <div class="relative cursor-pointer">
        <div
          class="h-[.1rem] ssm:w-[17rem] xl:w-[80rem] m-auto bg-[#FFFFFF] opacity-20 mt-8"
        ></div>
        <div
          class="absolute bottom-[-6px] right-[50%]"
          @click="toggleFooter = !toggleFooter"
        >
          <img
            src="@/assets/home/header/down-arrow.png"
            alt="inconic-down"
            class="w-[1.5rem] h-[1.5rem] rounded-full p-1"
            v-if="toggleFooter"
          />
          <img
            src="@/assets/home/header/uparrow.png"
            alt="inconic-up"
            class="w-[.9rem] h-[.9rem] rounded-full p-1 bg-white mt-8"
            v-else
          />
        </div>
      </div>
      <div class="w-max m-auto" v-if="toggleFooter">
      <div
        class="!text-white content text-start mt-4 w-[20rem] md:w-[43rem] lg:w-[60rem] xl:w-[75rem]"
        v-html="footerContent.content"
      ></div>
    </div>
      <div class="text-white text-center py-4 text-sm">
        © 2024 Sharda ONLINE
      </div>
    </div>
    <!--desktop desgin-->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "footerView",
  data() {
    return {
      toggleProgramm: false,
      toggleSharda: false,
      toggleOtherLink: false,
      footerContent: [],
      toggleFooter: false,
    };
  },
  created() {
    this.footerData();
  },
  methods: {
    async footerData() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/footer/?slug=${this.$route.path}`
        )
        .then((resp) => {
          this.footerContent = resp.data.data[0];
          console.log(this.footerContent, "here there is god boss6");
        });
    },
  },
};
</script>

<style scoped></style>
